import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import FAQs from "../components/Repeating/FAQs";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import StatesWeServe from "../components/Repeating/StatesWeServe";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";

import betterMortgageRate from "../images/4.0 Refinance/Better Mortgage Rate.svg";
import cashInYourPocket from "../images/4.0 Refinance/Cash in Your Pocket.svg";
import lowerMonthlyPayment from "../images/4.0 Refinance/Lower Monthly Payment.svg";
import shorterTerm from "../images/4.0 Refinance/Shorter Term.svg";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const faqs = [
    {
      question: "What housing markets do you serve?",
      answer: (
        <>
          <p className="mb-0">
            We serve eight states, with areas as diverse as{" "}
            <Link
              to="/tempe-mortgage-lender/"
              className="font-normal text-typography-body no-underline"
            >
              Tempe
            </Link>
            , Arizona,{" "}
            <Link
              to="/las-vegas-mortgage-lender/"
              className="font-normal text-typography-body no-underline"
            >
              Las Vegas
            </Link>
            , Nevada, and{" "}
            <Link
              to="/san-bernardino-mortgage-lender/"
              className="font-normal text-typography-body no-underline"
            >
              San Bernardino
            </Link>
            , California.
          </p>
        </>
      ),
    },
    {
      question: "When is it a smart move to refinance?",
      answer: (
        <>
          <p>
            The typical rule of thumb is that when interest rates drop by 1% or
            more, it’s a good time to consider refinancing your home loan. You
            can save money in the long-term, build equity in your home more
            quickly, and reduce your monthly payment.
          </p>
          <p>
            Falling interest rates are also a good time to convert from a
            fixed-rate loan to an adjustable-rate mortgage (ARM) if you want to
            take advantage of the dip.
          </p>
          <p className="mb-0">
            On the flip side, if you’ve found that you don’t like the
            fluctuations that come with an adjustable-rate mortgage, then
            refinancing to a fixed-rate loan can give you stability.
          </p>
        </>
      ),
    },
    {
      question: "Can I refinance if I have bad credit?",
      answer: (
        <>
          <p className="mb-0">
            A strong credit score is key to getting a great interest rate, but
            there are loan programs for those with less than perfect credit.
            Your lender can help identify the best loan opportunities for you.
          </p>
        </>
      ),
    },
    {
      question: "Will there be out-of-pocket costs?",
      answer: (
        <>
          <p className="mb-0">
            Yes. You’ll need to account for closing costs to cover the fees for
            title insurance, appraisal, taxes, and transfer fees, among others.
            This typically runs between 3% and 6% of the loan’s principal. There
            are some loan programs with no closing costs, but it’s important to
            note that typically the fees will be recouped by giving you a higher
            interest rate.
          </p>
        </>
      ),
    },
    {
      question: "Can I refinance into a longer-term mortgage?",
      answer: (
        <>
          <p className="mb-0">
            Yes, you can. However, while the lower monthly payments you’ll get
            from amortizing over a longer period can be appealing, it’s
            important to note that the longer term will dramatically increase
            the amount of interest you’ll pay over the life of the loan.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Home Refinance | Right Start | Mortgage Lender"
        description="Learn how a home refinance can make great financial sense in so many different ways. From lower interest rates to shorter terms, discover the benefits."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-28 md:pt-12">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/4.0 Refinance/1.0 refinance-hero.png"
                loading="eager"
                width={560}
              />
            </div>
            <div>
              <h1>Home Refinance</h1>
              <p>
                Refinancing your home loan makes great financial sense in so
                many different ways. You can get a lower interest rate, put cash
                in your pocket, refinance for a lower monthly payment, or
                shorten the term and pay off your loan much earlier. You decide
                the best move for you.
              </p>
              <ButtonSolid
                href="/request-rates/"
                text="Request Rates"
                altStyle={2}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 bg-primary-50 py-20 md:mb-32 md:py-32">
        <div className="container">
          <header className="mb-12 md:mb-24">
            <h2>Your Guide to Refinancing</h2>
          </header>

          <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
            <ScrollNavigation className="hidden md:col-span-4 md:col-start-1 md:block">
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="Benefits"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-2"}
                  title="Why Refinance"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-3"}
                  title="How it works"
                  stripHash
                />
              </li>
            </ScrollNavigation>

            <div className="space-y-12 md:col-span-8 md:col-end-13 md:space-y-24">
              <div
                id="section-1"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <header className="mb-10 md:mb-12">
                  <h3 className="heading-four">The Benefits</h3>
                </header>
                <div className="grid gap-8 md:grid-cols-2 md:gap-10">
                  <div>
                    <img
                      src={betterMortgageRate}
                      alt="Better Mortgage Rate"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Better Mortgage Rate</h3>
                    <p className="mb-0">
                      Take advantage of falling interest rates to get better
                      terms on your loan and save money.
                    </p>
                  </div>

                  <div>
                    <img
                      src={lowerMonthlyPayment}
                      alt="Lower Monthly Payment"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Lower Monthly Payment</h3>
                    <p className="mb-0">
                      Give yourself some extra breathing room in your budget
                      with a lower monthly mortgage bill.
                    </p>
                  </div>

                  <div>
                    <img
                      src={cashInYourPocket}
                      alt="Cash in Your Pocket"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Cash in Your Pocket</h3>
                    <p className="mb-0">
                      Borrow money against your home equity to fund a project,
                      consolidate debts, or for any other reason.
                    </p>
                  </div>

                  <div>
                    <img
                      src={shorterTerm}
                      alt="Shorter Term"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Shorter Term</h3>
                    <p className="mb-0">
                      Refinance from a 30-year home loan to a 15-year and pay it
                      off in half the time.
                    </p>
                  </div>
                </div>
              </div>

              <div
                id="section-2"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Why Refinance</h3>
                <p className="mb-0">
                  Refinancing is simply replacing your current mortgage with a
                  new loan. Your interest rate may go down, you may transition
                  from a 30-year to a 15-year term, or even shift from an
                  adjustable-rate mortgage (ARM) to a fixed rate. The bottom
                  line is that all of this can help you save money, get a lump
                  sum of cash, or help you pay off your loan early.
                </p>
              </div>

              <div
                id="section-3"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">How it Works</h3>
                <p className="mb-0">
                  You begin by establishing your goal. What are you hoping to
                  get out of refinancing your home? Once you decide, you can
                  work with a lender to compare rates. And when you’re ready to
                  begin, simply apply and submit your documentation.
                  Underwriters will review your application and work with you to
                  close on your new loan. It’s that easy!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FAQs heading="FAQs" uniqueFaqs={faqs} />
      <Testimonials />
      <ValueProps />
      <About />
      <StatesWeServe />
      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
